import clsx from 'clsx';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './index.module.scss';

export type ViewModel = {
  videoUrl: string;
};

type PropTypes = {
  vm: ViewModel;
};

export default function SectionVideo({vm}: PropTypes) {
  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <video
          className={styles.video}
          controls
          src={vm.videoUrl}
        ></video>
      </div>
    </section>
  );
}
