// extracted by mini-css-extract-plugin
export var arrow = "index-module--arrow--YTc8N";
export var bg = "index-module--bg--CiX+x";
export var card = "index-module--card--8WS9z";
export var carouselContainer = "index-module--carousel-container--lAY7k";
export var cert = "index-module--cert--NkcKt";
export var content = "index-module--content--1Z+DO";
export var extra = "index-module--extra--fhGaR";
export var name = "index-module--name--QzPaC";
export var placeholder = "index-module--placeholder--WEnXH";
export var section = "index-module--section--SLjky";
export var title = "index-module--title--e24Ql";