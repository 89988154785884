import {Col, Row} from 'antd';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React, {useState} from 'react';
import {GAP_SIZE_BASE} from '../../constants';
import * as styles from './index.module.scss';

export type ViewModel = {
  cards: {
    title: string;
    subTitle: string;
    description: string;
    bgUrl: string;
    link: string;
  }[];
};

type PropTypes = {
  vm: ViewModel;
};

export default function SectionDualCard({vm}: PropTypes) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  return (
    <section className={styles.section}>
      <Row gutter={GAP_SIZE_BASE} justify="space-between" align="stretch">
        {vm.cards.map((card, index) => {
          const delt = activeIndex === index ? 4 : -4;
          const md = activeIndex === null ? 12 : 12 + delt;
          return (
            <Col
              key={card.title}
              span={24}
              md={md}
              style={{transition: 'all .25s'}}
            >
              <Link to={card.link}>
                <div
                  className={clsx(styles.card)}
                  style={{backgroundImage: `url(${card.bgUrl})`}}
                  onMouseLeave={() => setActiveIndex(null)}
                  onMouseEnter={() => setActiveIndex(index)}
                >
                  <Col
                    span={0}
                    md={24}
                    className={clsx(
                      styles.mask,
                      activeIndex !== null &&
                        activeIndex !== index &&
                        styles.show
                    )}
                  />
                  <div className={styles.content}>
                    <div className={styles.title}>{card.title}</div>
                    <div className={styles.subTitle}>{card.subTitle}</div>
                    <div>- - - - - - - - - - - - - - - -</div>
                    <div>{card.description}</div>
                  </div>
                </div>
              </Link>
            </Col>
          );
        })}
      </Row>
    </section>
  );
}
