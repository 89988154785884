import {Col, Row} from 'antd';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import {GAP_SIZE_2} from '../../constants';
import * as styles from './index.module.scss';

export type ViewModel = {
  list: {
    title: string;
    description: string;
  }[];
  tags: {
    title: string;
    subTitle: string;
  }[];
};

type PropTypes = {
  vm: ViewModel;
};

export default function SectionBrandOverview({vm}: PropTypes) {
  return (
    <section className={styles.section}>
      <StaticImage
        className={styles.bg}
        src="../../images/bg-silk.jpg"
        alt=""
      />
      <Row className={styles.content} justify="space-around" align="stretch">
        <Col>
          <StaticImage
            className={styles.decoration}
            placeholder="blurred"
            src="../../images/section-naming-decoration.svg"
            height={500}
            alt=""
          />
        </Col>
        <Col md={17} span={20}>
          <Row className={styles.list} gutter={[GAP_SIZE_2, GAP_SIZE_2]} align='middle'>
            {vm.list.map(({title, description}) => (
              <Col key={title} className={styles.listItem} span={24}>
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>{description}</div>
              </Col>
            ))}
            <Col span={24} xs={0} className={styles.divider} />
            {vm.tags.map(({title, subTitle}) => (
              <Col span={8} key={title} className={styles.tag}>
                <Link to="/about/#about-1">
                  <span className={styles.title}>{title}</span>
                  <div className={styles.subTitle}>{subTitle}</div>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </section>
  );
}
