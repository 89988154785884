import {Col, Row} from 'antd';
import clsx from 'clsx';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import {GAP_SIZE_3, GAP_SIZE_BASE} from '../../constants';
import * as styles from './index.module.scss';

export type ViewModel = {
  title: string;
  brands: {
    key: string;
    logo: string;
  }[];
};

type PropTypes = {
  vm: ViewModel;
};

export default function SectionCooperation({vm}: PropTypes) {
  return (
    <section className={styles.section}>
      <div className={styles.title}>
        <StaticImage
          className={styles.back}
          src="../../images/brand-cooperation.png"
          alt="BRAND COOPERATION"
          placeholder="tracedSVG"
          height={100}
        />
        <div className={styles.front}>{vm.title}</div>
      </div>
      <Row justify="center">
        <Col className={styles.brands} span={24} md={20}>
          <Row
            justify="space-around"
            align="stretch"
          >
            {vm.brands.map(brand => (
              <Col className={styles.brand} key={brand.key}>
                {/* TODO: 使用 Gatsby image */}
                <img src={brand.logo} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </section>
  );
}
