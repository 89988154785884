import React from 'react';
import {graphql, PageProps} from 'gatsby';
import DefaultLayout from '../layouts/default';
import LuHero, {ViewModel as HeroViewModel} from '../components/lu-hero';
import SectionBrandOverview, {
  ViewModel as BrandOverviewViewModel,
} from '../components/section-brand-overview';
import SectionVideo from '../components/section-video';
import SectionSlideshow, {
  ViewModel as SlideshowViewModel,
} from '../components/section-slideshow';
import slideshow0Image from '../images/section-slideshow-0.jpg';
import slideshow1Image from '../images/section-slideshow-1.jpg';
import slideshow2Image from '../images/section-slideshow-2.jpg';
import SectionCooperation, {
  ViewModel as CooperationViewModel,
} from '../components/section-cooperation';
import agnesbImage from '../images/logo-agnesb.jpg';
import aimerImage from '../images/logo-aimer.jpg';
import anlifangImage from '../images/logo-anlifang.jpg';
import armaniImage from '../images/logo-armani.jpg';
import dushilirenImage from '../images/logo-dushiliren.jpg';
import etamImage from '../images/logo-etam.jpg';
import pinkImage from '../images/logo-pink.jpg';
import septwolvesImage from '../images/logo-septwolves.jpg';
import triumphImage from '../images/logo-triumph.jpg';
import versaceImage from '../images/logo-versace.jpg';
import SectionCertification, {
  ViewModel as CertificationViewModel,
} from '../components/section-certification';
import certBvImage from '../images/certification-bv.jpg';
import certCleanDesignImage from '../images/certification-clean-design.jpg';
import certGotsImage from '../images/certification-gots.jpg';
import certGrsImage from '../images/certification-grs.jpg';
import certHiImage from '../images/certification-hi.jpg';
import certIsoImage from '../images/certification-iso.jpg';
import certOekoImage from '../images/certification-oeko.jpg';
import certVfImage from '../images/certification-vf.jpg';
import SectionDualCard, {
  ViewModel as DualCardViewModel,
} from '../components/section-dual-card';
import dualBg0Image from '../images/section-dual-card-0.jpg';
import dualBg1Image from '../images/section-dual-card-1.jpg';
import SectionVerticalSlide, {
  ViewModel as VerticalSlideViewModel,
} from '../components/section-vertical-slide';
import {useTranslation} from 'react-i18next';

const IndexPage = ({data, location}: PageProps) => {
  const {t} = useTranslation('index');
  const HERO_DATA: HeroViewModel = {
    title: [t('用新思维联结市场'), t('走和谐友好的发展之路')],
  };
  const BRAND_OVERVIEW_DATA: BrandOverviewViewModel = {
    list: [
      {
        title: t('“新”从时间的维度来说'),
        description: t(
          '未来是新鲜的，值得期待，但又充满挑战的，我们希望通过不断创新，跟上时代步伐，走出自己的特色之路。'
        ),
      },
      {
        title: t('“联”从市场的维度来说'),
        description: t(
          '竞争是趋于精细化的，也更激烈的，我们希望通过和上下游行业，同行的良性合作，充分联动，共同完成供给侧升级。'
        ),
      },
      {
        title: t('“和”从自然的维度来说'),
        description: t(
          '绿色经济才是可持续发展之路，不论从纺织非遗的技艺传承，还是从环保再生产业变革的角度，我们都希望能“和谐”共存，绿色发展。'
        ),
      },
    ],
    tags: [
      {
        title: '2000',
        subTitle: t('成立于2000年'),
      },
      {
        title: '1000',
        subTitle: t('在职员工1000多名'),
      },
      {
        title: t('20亿'),
        subTitle: t('年贸易总额20多亿'),
      },
    ],
  };
  const VIDEO_URL = 'https://zstatic.ydict.net/lultd-static/index-introduction.mp4';
  const SLIDESHOW_DATA: SlideshowViewModel = {
    slides: [
      {
        title: t('产业链完整'),
        imageUrl: slideshow0Image,
        link: '/about/#about-5',
      },
      {
        title: t('设备先进'),
        imageUrl: slideshow1Image,
        link: '/about/#about-7',
      },
      {
        title: t('ERP智慧工厂'),
        imageUrl: slideshow2Image,
        link: '/about/#about-4',
      },
    ],
  };
  const DUAL_CARD_DATA: DualCardViewModel = {
    cards: [
      {
        title: t('创新设计'),
        subTitle: 'innovate',
        description: t(
          '新联和集团和国内著名高校共同合作，致力研发原料创新和蕾丝花型创新'
        ),
        bgUrl: dualBg0Image,
        link: '/responsibility/#respon-2',
      },
      {
        title: t('精英团队'),
        subTitle: 'elite',
        description: t(
          '多年来，新联和集团不断从中国纺织专业的大学引进人才，持续发展壮大自己的人才队伍'
        ),
        bgUrl: dualBg1Image,
        link: '/about/#about-3',
      },
    ],
  };
  const VERTICAL_SLIDE_DATA: VerticalSlideViewModel = {
    slides: [
      {
        title: t('品质保证'),
        description: t(
          '经国际领先的监测组织BV认证通过的监测技术中心，所有的产品符合ISO/GB/AATCC标准。'
        ),
        link: '/brands',
      },
      {
        title: t('品牌认可'),
        description: t(
          '2017年，我司投资新建标准条件实验室，引进国际先进检测实验设备，并于2017年10月获得BV颁发的实验室认可证书。'
        ),
        link: '/about/#about-6',
      },
    ],
  };
  const CERTIFICATION_DATA: CertificationViewModel = {
    title: t('优势认证'),
    extra: '/  Aptitude and Certification  /',
    certifications: [
      {
        name: 'Oeko',
        imageUrl: certOekoImage,
      },
      {
        name: 'GRS',
        imageUrl: certGrsImage,
      },
      {
        name: t('ISO证书'),
        imageUrl: certIsoImage,
      },
      {
        name: 'BV',
        imageUrl: certBvImage,
      },
      {
        name: 'VF',
        imageUrl: certVfImage,
      },
      {
        name: 'CLEAN-DESIGN',
        imageUrl: certCleanDesignImage,
      },
      {
        name: 'GOTS',
        imageUrl: certGotsImage,
      },
      {
        name: 'Higg Index',
        imageUrl: certHiImage,
      },
    ],
  };
  const COOPERATION_DATA: CooperationViewModel = {
    title: t('与国内外众多品牌建立合作'),
    brands: [
      {
        key: 'Triumph',
        logo: triumphImage,
      },
      {
        key: 'aimer',
        logo: aimerImage,
      },
      {
        key: t('都市丽人'),
        logo: dushilirenImage,
      },
      {
        key: 'PINK',
        logo: pinkImage,
      },
      {
        key: 'Etam',
        logo: etamImage,
      },
      {
        key: t('安莉芳'),
        logo: anlifangImage,
      },
      {
        key: 'VERSACE',
        logo: versaceImage,
      },
      {
        key: 'ARMANI EXCHANGE',
        logo: armaniImage,
      },
      {
        key: 'agnesb',
        logo: agnesbImage,
      },
      {
        key: 'SEPTWOLVES',
        logo: septwolvesImage,
      },
    ],
  };
  return (
    <DefaultLayout location={location}>
      <section>
        <LuHero vm={HERO_DATA} />
      </section>
      <SectionBrandOverview vm={BRAND_OVERVIEW_DATA} />
      <SectionVideo
        vm={{
          videoUrl: VIDEO_URL,
        }}
      />
      <SectionSlideshow vm={SLIDESHOW_DATA} />
      <SectionDualCard vm={DUAL_CARD_DATA} />
      <SectionVerticalSlide vm={VERTICAL_SLIDE_DATA} />
      <SectionCertification vm={CERTIFICATION_DATA} />
      <SectionCooperation vm={COOPERATION_DATA} />
    </DefaultLayout>
  );
};

export default IndexPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
