import {Button, Carousel, Col, Divider, Row} from 'antd';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import LeftAngle from '../../svgs/left-angle';
import RightAngle from '../../svgs/right-angle';
import * as styles from './index.module.scss';
import './index.scss';

export type ViewModel = {
  /** 大标题 */
  title: string;
  /** 标题旁小字 */
  extra?: string;
  /** 证书图片 */
  certifications: {
    imageUrl: string;
    name: string;
  }[];
};

type PropTypes = {
  vm: ViewModel;
};

export default function SectionCertification({vm}: PropTypes) {
  return (
    <section className={styles.section}>
      <StaticImage
        className={styles.bg}
        src="../../images/section-certification-bg.jpg"
        alt=""
      />
      <Row className={styles.content} justify="center">
        <Col span={18}>
          <div className={clsx('boxed-text', styles.title)}>
            <span>{vm.title}</span>
            <wbr />
            <span className={styles.placeholder} />
            <span className={styles.extra}>{vm.extra}</span>
          </div>
          <div className={styles.carouselContainer}>
            <Carousel
              className="certification-carousel"
              arrows
              autoplay={false}
              nextArrow={
                <div>
                  <div className={styles.arrow}>
                    <RightAngle />
                  </div>
                </div>
              }
              prevArrow={
                <div>
                  <div className={styles.arrow}>
                    <LeftAngle />
                  </div>
                </div>
              }
              dots={false}
              swipeToSlide
              responsive={[
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
              slidesToShow={5}
            >
              {vm.certifications.map(cert => (
                <div key={cert.name} className={styles.cert}>
                  <Link to="/about/#about-6">
                    <div className={styles.card}>
                      <img src={cert.imageUrl} alt={cert.name} />
                      <div className={styles.name}>{cert.name}</div>
                    </div>
                  </Link>
                </div>
              ))}
            </Carousel>
          </div>
        </Col>
      </Row>
    </section>
  );
}
