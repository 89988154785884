import React from 'react';

const svgStyle = {
  height: '1em',
};

export default function RightAngle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.023"
      height="47.218"
      viewBox="0 0 25.023 47.218"
      style={svgStyle}
    >
      <path
        id="路径_2"
        data-name="路径 2"
        d="M4725.713,4747.273l22.9-22.9-22.9-22.9"
        transform="translate(-4725.006 -4700.763)"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
}
