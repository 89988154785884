import React from 'react';

const svgStyle = {
  height: '1em',
};

export default function ArrowRightCommon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 87.91 8.18"
      style={svgStyle}
    >
      <g id="图层_2" data-name="图层 2">
        <g id="design">
          <line
            fill="none"
            stroke="currentColor"
            strokeMiterlimit={10}
            y1="4.09"
            x2="86.82"
            y2="4.09"
          />
          <polygon
            fill="currentColor"
            points="83.51 8.18 82.83 7.45 86.44 4.09 82.83 0.73 83.51 0 87.91 4.09 83.51 8.18"
          />
        </g>
      </g>
    </svg>
  );
}
