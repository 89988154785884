import React from 'react';
import {Row, Col} from 'antd';
import * as styles from './index.module.scss';
import bgVideo from '../../images/index-hero.gif';

export type ViewModel = {
  title: string[];
};

type PropTypes = {
  vm: ViewModel;
};

export default function LuHero({vm}: PropTypes) {
  return (
    <div className={styles.container}>
      <img
        className={styles.bg}
        src={bgVideo}
      />
      <Row className={styles.hero} justify="center" align="middle">
        <Col className={styles.text}>
          {vm.title.map(text => (
            <h1 key={text}>{text}</h1>
          ))}
        </Col>
      </Row>
    </div>
  );
}
