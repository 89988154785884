import clsx from 'clsx';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import LuSlideshow from '../lu-slideshow';
import * as styles from './index.module.scss';

export type ViewModel = {
  slides: {
    title: string;
    imageUrl: string;
    link: string;
  }[];
};

type PropTypes = {
  vm: ViewModel;
};

export default function SectionSlideshow({vm}: PropTypes) {
  return (
    <section className={styles.section}>
      <LuSlideshow slides={vm.slides} />
    </section>
  );
}
