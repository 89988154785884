import {Col, Row, Carousel} from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {GatsbyImage, StaticImage} from 'gatsby-plugin-image';
import React, {useState} from 'react';
import ArrowRight from '../../svgs/arrow-right';
import ArrowRightCommon from '../../svgs/arrow-right-common';
import * as styles from './index.module.scss';

type PropTypes = {
  slides: {
    title: string;
    imageUrl: string;
    link: string;
  }[];
};

export default function LuSlideshow({slides}: PropTypes) {
  const [activeIndex, setIndex] = useState(0);
  const sliderRef = React.useRef<CarouselRef>(null);
  return (
    <Row className={styles.slideshow}>
      <Col sm={0} span={24}>
        {/* 移动端中的侧边栏 */}
        <div className={styles.smOptions}>
          {slides.map((slide, index) => (
            <div
              className={clsx(
                styles.option,
                index === activeIndex && styles.active
              )}
              onClick={() => {
                sliderRef.current?.goTo(index);
              }}
            >
              {slide.title}
            </div>
          ))}
        </div>
      </Col>
      <Col sm={8} span={0}>
        {/* 大屏幕中的侧边栏 */}
        <div className={styles.options}>
          {slides.map((slide, index) => (
            <Link className={styles.link} to={slide.link ?? '#'}>
              <Row
                key={slide.title}
                className={clsx(
                  styles.slide,
                  activeIndex === index && styles.active
                )}
                justify="space-around"
                align="middle"
                onMouseOver={() => sliderRef.current?.goTo(index, true)}
              >
                <Col lg={12} span={24}>
                  {slide.title}
                </Col>
                <Col lg={12} span={0} className={styles.decoration}>
                  <ArrowRightCommon />
                </Col>
              </Row>
            </Link>
          ))}
        </div>
      </Col>
      <Col sm={16} span={24}>
        <Carousel
          ref={sliderRef}
          infinite
          swipe
          draggable
          dots={false}
          slidesToShow={1}
          beforeChange={(_, index) => setIndex(index)}
        >
          {slides.map((slide, index) => (
            <Link to={slide.link}>
              <img
                className={styles.image}
                src={slide.imageUrl}
                alt={slide.title}
              />
            </Link>
          ))}
        </Carousel>
      </Col>
    </Row>
  );
}
