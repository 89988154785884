import {Col, Row} from 'antd';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import ArrowRightCommon from '../../svgs/arrow-right-common';
import * as styles from './index.module.scss';

export type ViewModel = {
  slides: {
    title: string;
    description: string;
    link: string;
  }[];
};

type PropTypes = {
  vm: ViewModel;
};

const GAP = 16;

export default function SectionVerticalSlide({vm}: PropTypes) {
  return (
    <section className={styles.section}>
      <Row gutter={[GAP, GAP]}>
        <Col span={24} lg={14}>
          <StaticImage
            src="../../images/section-vertical-slide-bg.jpg"
            alt=""
          />
        </Col>
        <Col span={24} lg={10}>
          <Row gutter={[GAP, GAP]} className={styles.cards}>
            {vm.slides.map((slide, index) => (
              <Col key={slide.title} flex={1}>
                <Link to={slide.link}>
                  <div className={styles.card}>
                    <StaticImage
                      className={styles.bg}
                      src="../../images/bg-silk.jpg"
                      alt=""
                      objectFit="none"
                      objectPosition={`25% ${25 + index * 28}%`}
                    />
                    <div className={styles.content}>
                      <div className={styles.title}>{slide.title}</div>
                      <div className={styles.description}>
                        {slide.description}
                      </div>
                      <div className={styles.decoration}>
                        <ArrowRightCommon />
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </section>
  );
}
